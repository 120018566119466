<template>
  <form @submit.prevent="submitForm">
    <select ref="select">
      <option disabled selected>Статья расхода</option>
      <optgroup
        v-for="(category, index) in categories"
        :key="category.id + index"
        :label="category.name"
      >
        <option
          v-for="item in category.elements"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </optgroup>
    </select>
    <div
      class="consumption_input"
      v-for="(consumption, index) in consumptions"
      :key="index"
      ref="consumptions"
    >
      <input
        type="number"
        placeholder="Кол-во"
        v-model.number="consumption.amount"
      />
      <span>x</span>
      <input
        type="number"
        placeholder="Сумма"
        v-model.number="consumption.sum"
      />
      <span>руб.</span>
    </div>
    <button class="add_more_btn" @click.prevent="addConsumption">
      + добавить еще
    </button>
    <div class="date_input">
      <input
        type="date"
        placeholder="Дата"
        ref="date"
        :value="currentDate"
        v-once
      />
    </div>
    <div class="populars" :class="{ 'is-active': populars_shown }" v-if = "popular_items">
      <p class="populars_title" @click="populars_shown = !populars_shown">
        Частые расходы
      </p>
      <div class="populars_container">
        <ul class="populars_list" v-for="(categoryValue, categoryKey, index) in popular_items" :key="categoryKey + index">
          <p class="populars_list_title">{{ categoryValue.name }}</p>
          <li class = "populars_list_el" v-for="(value, key, index) in categoryValue.prices" :key = "key + index" @click = "addConsumption(1, key, categoryKey)">
            <p class="populars_el_sum">{{ key }} руб.</p>
          </li>
        </ul>
      </div>
    </div>
    <button class="submit_btn accounting_btn red_btn" type="submit">
      {{ querySuccess ? "Расход успешно добавлен!" : "Добавить расход" }}
    </button>
  </form>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('queryPopularConsumptions');
  },
  data() {
    return {
      currentDate: new Date().toISOString().substr(0, 10),
      populars_shown: false,
      consumptions: [
        {
          amount: 1,
          sum: 0,
        },
      ],
      querySuccess: false,
    };
  },
  computed: {
    categories() {
      return this.$store.getters["getExpansesCategories"];
    },
    popular_items() {
      return this.$store.state['popular_consumptions'];
    }
  },
  methods: {
    addConsumption(amount, price, state) {
      if (state) {
        amount = amount ? amount : 1;
        price = price ? price : 0;
        this.$refs.select.value = state;
        this.consumptions[0] = {
          amount: amount,
          sum: price,
        };
        this.populars_shown = false;
      } else {
        this.consumptions.push({
          amount: 1,
          sum: 0,
        });
      }
    },
    async submitForm() {
      const selectValue = this.$refs.select.value;
      const dateValue = this.$refs.date.value;

      if (selectValue == "Статья расхода") {
        this.$refs.select.classList.add("error");
        setTimeout(() => {
          this.$refs.select.classList.remove("error");
        }, 2000);
        return;
      }

      this.consumptions = this.consumptions.filter(
        (item) => item.amount * item.sum != 0
      );
      if (this.consumptions.length == 0) {
        this.$refs.consumptions.classList.add("error");
        setTimeout(() => {
          this.$refs.consumptions.classList.remove("error");
        }, 2000);
        this.consumptions = [
          {
            amount: 1,
            sum: 0,
          },
        ];
        return;
      }
      if (!dateValue) {
        this.$refs.date.classList.add("error");
        setTimeout(() => {
          this.$refs.date.classList.remove("error");
        }, 2000);
        return;
      }

      // Отправка запроса
      const response = await fetch("/php/expenses/addItem.php", {
        method: "POST",
        headers: { "Content-Type": "application/json;charset=utf-8" },
        body: JSON.stringify({
          state: selectValue,
          date: dateValue,
          consumptions: this.consumptions,
        }),
      });

      const answer = await response.json();

      if (answer.errors) {
        alert("Произошла ошибка, см. консоль");
        console.log("Ошибка: " + answer.message);
        return false;
      }

      this.querySuccess = true;

      setTimeout(() => {
        this.querySuccess = false;
      }, 4000);

      // #Отправка запроса#

      this.consumptions = [
        {
          amount: 1,
          sum: 0,
        },
      ];
      this.$refs.select.value = "Статья расхода";
      this.$refs.date.value = this.currentDate;
    }
  },
};
</script>

<style>
</style>