<template>
  <form @submit.prevent="submitForm">
    <select ref="select">
      <option disabled selected>Статья прихода</option>
      <optgroup
        v-for="(category, index) in categories"
        :key="category.id + index"
        :label="category.name"
      >
        <option
          v-for="item in category.elements"
          :key="item.id"
          :value="item.id"
        >
          {{ item.name }}
        </option>
      </optgroup>
    </select>
    <div class="common_input">
      <input type="number" placeholder="Сумма" ref="price" />
      <span>руб.</span>
    </div>
    <div class="date_input">
      <input
        type="date"
        placeholder="Дата"
        ref="date"
        :value="currentDate"
        v-once
      />
    </div>
    <div
      class="populars"
      :class="{ 'is-active': populars_shown }"
      v-if="popular_items"
    >
      <p class="populars_title" @click="populars_shown = !populars_shown">
        Частые приходы
      </p>
      <div class="populars_container">
        <ul
          class="populars_list"
          v-for="(categoryValue, categoryKey, index) in popular_items"
          :key="categoryKey + index"
        >
          <p class="populars_list_title">{{ categoryValue.name }}</p>
          <li
            class="populars_list_el"
            v-for="(value, key, index) in categoryValue.prices"
            :key="key + index"
            @click="addComing(key, categoryKey)"
          >
            <p class="populars_el_sum">{{ key }} руб.</p>
          </li>
        </ul>
      </div>
    </div>
    <button class="submit_btn accounting_btn green_btn" type="submit">
      {{ querySuccess ? "Успешно добавлено!" : "Добавить приход" }}
    </button>
  </form>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("queryPopularComings");
  },
  data() {
    return {
      currentDate: new Date().toISOString().substr(0, 10),
      querySuccess: false,
      populars_shown: false,
    };
  },
  computed: {
    categories() {
      return this.$store.getters["getComesCategories"];
    },
    popular_items() {
      return this.$store.state["popular_comings"];
    },
  },
  methods: {
    async submitForm() {
      const selectValue = this.$refs.select.value;
      const dateValue = this.$refs.date.value;
      const priceValue = this.$refs.price.value;

      if (selectValue == "Статья прихода") {
        this.$refs.select.classList.add("error");
        setTimeout(() => {
          this.$refs.select.classList.remove("error");
        }, 4000);
        return;
      }
      if (!priceValue) {
        this.$refs.price.classList.add("error");
        setTimeout(() => {
          this.$refs.price.classList.remove("error");
        }, 4000);
        return;
      }
      if (!dateValue) {
        this.$refs.date.classList.add("error");
        setTimeout(() => {
          this.$refs.date.classList.remove("error");
        }, 4000);
        return;
      }

      // Отправка запроса
      const response = await fetch("/php/comes/addItem.php", {
        method: "POST",
        headers: { "Content-Type": "application/json;charset=utf-8" },
        body: JSON.stringify({
          state: selectValue,
          date: dateValue,
          sum: priceValue,
        }),
      });

      const answer = await response.json();

      if (answer.errors) {
        alert("Произошла ошибка, см. консоль");
        console.log("Ошибка: " + answer.message);
        return false;
      }

      this.querySuccess = true;

      setTimeout(() => {
        this.querySuccess = false;
      }, 4000);

      this.$refs.select.value = "Статья прихода";
      this.$refs.date.value = this.currentDate;
      this.$refs.price.value = "";

      // #Отправка запроса#
    },
    addComing(price, categoryId) {
      this.$refs.select.value = categoryId;
      this.$refs.price.value = price;
      this.populars_shown = false;
    },
  },
};
</script>

<style>
</style>