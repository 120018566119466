<template>
  <section class="accounting">
    <button class="accounting_btn green_btn" @click="chooseComponent('Coming')">
      Приход
    </button>
    <button
      class="accounting_btn red_btn"
      @click="chooseComponent('Consumption')"
    >
      Расход
    </button>
  </section>
  <keep-alive>
    <component :is="activeComponent" />
  </keep-alive>
</template>

<script>
import Coming from "./parts/accounting/Coming";
import Consumption from "./parts/accounting/Consumption";

export default {
  components: {
    Coming,
    Consumption,
  },
  data() {
    return {
      activeComponent: null,
    };
  },
  methods: {
    chooseComponent(component) {
      this.activeComponent = component;
    },
  },
};
</script>

<style lang = "scss">
.accounting {
  margin-bottom: 25px;
}
/* Btns */
.accounting_btn {
  display: inline-block;
  box-sizing: border-box;
  max-width: 48%;
  width: 100%;
  padding: 16px 10px;
  margin-right: 4%;
  color: white;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  &:last-of-type {
    margin-right: 0;
  }
}
.submit_btn {
  max-width: 100%;
  margin-top: 10px;
}
.green_btn {
  background-color: #66b560;
  &:hover {
    background-color: #429c3d;
  }
}

.red_btn {
  background-color: #e15353;
  &:hover {
    background-color: #b33131;
  }
}

.populars {
  &_title {
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    border: 1px solid white;
    padding: 16px 0;
    &:after {
      content: "";
      transform: rotateZ(90deg);
      text-decoration: none;
    }
  }
  &_list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-column-gap: 8px;
    grid-row-gap: 16px;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease-out;

    &_title {
      margin-bottom: 0;
      width: 100%;
      grid-column: 1 / -1;
    }
    
    &_el {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid white;
      cursor: pointer;
      font-size: 12px;
      transition: background-color .3s ease-out;

      &:hover {
        background-color: rgba(255,255,255,.2);
      }
    }
  }
  &.is-active {
    margin-bottom: 40px;
    .populars_list {
      margin-bottom: 10px;
      height: auto;
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>